/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetData, apiUpdateData } from "../../services/api.service";
import { API_USER } from "../../constants/apiUrls";

const initialState = {
  user: null,
  loading: "idle", // 'idle' | 'pending'
  error: null,
  isUpdated: false,
};

export const getUserById = createAsyncThunk("@user/getuserbyId", async (userId, thunkAPI) => {
  const url = `${API_USER}/${userId}`;
  try {
    const response = await apiGetData(url);
    const resposeData = response.data?.response;
    return resposeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateUserById = createAsyncThunk("@user/updateUserById", async (data, thunkAPI) => {
  const url = `${API_USER}/${data.userId}`;
  try {
    const response = await apiUpdateData(url, data.data);
    const resposeData = response.data?.response;
    // store.dispatch(showToaster({type: 'success', text: 'Updated user details!.'}))
    return resposeData.message;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserState: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserById.pending, (state) => {
      state.loading = "pending";
      state.isUpdated = false;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.loading = "idle";
      state.user = action.payload;
    });
    builder.addCase(getUserById.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });

    builder.addCase(updateUserById.pending, (state) => {
      state.loading = "pending";
      state.isUpdated = false;
    });
    builder.addCase(updateUserById.fulfilled, (state, action) => {
      state.loading = "idle";
      if (action.payload) {
        state.isUpdated = true;
      }
      state.user = action.payload;
    });
    builder.addCase(updateUserById.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
      state.isUpdated = false;
    });
  },
});

// selectors
export const userSelector = (state) => state.user.user;
export const userUpdatedSelector = (state) => state.user.isUpdated;

// export const {} = userSlice.actions
export const { clearUserState } = userSlice.actions;

export default userSlice.reducer;
