import React from "react";
import styles from './spinner.module.scss';

const Spinner = ({isGlobal=false}) => {

    return(
        <div className={`${styles.load_container} ${isGlobal ? styles.global : ''}`}>
            {/* <div className={styles.moderspinner} /> */}
            <span className={`${styles.dots_circle_spinner} ${styles.loading}`} />
        </div>
    )
};

export default Spinner;