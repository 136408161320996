/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from '@mdi/js';
import { NavLink } from "react-router-dom";
import styles from './dropdownLink.module.scss';

const DropdownLink = ({icon, items=[], dropdownText, isExpand}) => {
    const [isOpen, setIsOpen] = useState(false);

    const openDropdown = () => {
        setIsOpen(prev => !prev)
    }
    // useEffect(() => {
    //     if(isOpen){toggleFreez(true)} else {toggleFreez(false)}
    // },[isOpen, toggleFreez]);

    return (
        <div className={`${styles.side_dropdown} ${isExpand && styles.expanded}`}>
            <button className={styles.dropdown_btn} type='button' onClick={openDropdown}>
                <Icon path={icon} size={1.4} color='#fff' />
                <div className={styles.hide_if_not_expanded}>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className={styles.link_text}>{dropdownText}</p>
                        <Icon rotate={isOpen ? 180 : 0} path={mdiChevronDown} size={1.6} color='#fff' />
                    </div>
                </div>
            </button>
            {(isOpen && isExpand) && 
            <div>
                {
                    items.map(item => <NavLink key={item.link} to={item.link} className={({ isActive }) => isActive ? `${styles.side_link} ${styles.active}` : styles.side_link }>
                    <p className={styles.link_text}>{item.text}</p>
                    </NavLink> )
                }
                
            </div>}
        </div>
    )
};

export default DropdownLink;