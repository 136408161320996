/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logout, registerUser, resetFirstPasswordPostData, signInUser, verifyUser } from '../../services/auth.service';
// const dispatch = useDispatch();

const initialState = {
    auth: null,
    // isSignupSuccess: false,
    // isSignupError: false,
    // signupSuccessMsg: "",
    // signupErrorMessage: "",

    // isSignedupUserExist: false,
    // isSignupVerificationSuccess: false,
    // isSignupVerificationError: false,
    // signupSuccessVerificationSuccessMessage: "",
    // signupSuccessVerificationErrorMessage: "",
    token: "",
    userId: "",
    profileType: "",

    isLoginSuccess: "",
    isLoginError: "",
    loginSuccessMessage: null,
    loginFailureMessage: null,
    isFirstPasswordReset: "",

    firstPasswordResetError: null,
    firstPasswordResetFailureMessage: null,
    firstPasswordResetSuccessMessage : null,

};

// eslint-disable-next-line no-unused-vars, consistent-return
// export const signUpUser = createAsyncThunk('signupUser', async (data, thunkAPI) => {
//     try {
//         const response = await registerUser(data);
//         const responseData = response.data;
//         if (responseData.statusCode === 200) {
//             return responseData
//         } thunkAPI.rejectWithValue(data);
//     }
//     catch (error) {
//         return (thunkAPI.rejectWithValue(error.response.data))
//     }
// })

// eslint-disable-next-line no-unused-vars, consistent-return
// export const signupVerificationHandler = createAsyncThunk('verifySignupUser', async (data, thunkAPI) => {
//     try {
//         const response = await verifyUser(data);
//         const resposeData = response.data;
//         if (resposeData.statusCode === 200) {
//             return resposeData.response
//         } thunkAPI.rejectWithValue(data);
//     } catch (error) {
//         return (thunkAPI.rejectWithValue(error.response.data))

//     }
// });

// eslint-disable-next-line consistent-return
export const loginUser = createAsyncThunk('logInUser', async (data, thunkAPI) => {
    try {
        const response = await signInUser(data);
        const resposeData = response.data;
        if (resposeData.statusCode === 200) {
            return resposeData
        } thunkAPI.rejectWithValue(data);
        // return resposeData.response
    } catch (error) {
        return (thunkAPI.rejectWithValue(error.response.data))
    }
});

// eslint-disable-next-line consistent-return
export const resetFirstPassword = createAsyncThunk('resetFirstPassword', async (data, thunkAPI) => {
    try {
        const response = await resetFirstPasswordPostData(data);
        const resposeData = response.data;
            return resposeData;
    } catch (error) {
        return (thunkAPI.rejectWithValue(error.response.data))
    }
});

//  Logout User
export const logoutUser = createAsyncThunk('auth/logout', async () => {
    await logout();
});


const signUpSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // profileType: (state, action) => {
        //     state.profileType = action.payload;
        // },
        clearState: (state) => {
            state.token = "";
            state.userId = "";
            state.profileType = "";
            state.isLoginError = false;
            state.isLoginSuccess = false;
            state.loginFailureMessage = null;
            state.loginSuccessMessage = null;
            state.isFirstPasswordReset = null;
            localStorage.clear()
        },
    },
    extraReducers: (builder) => {
        
        // Login/Sign In Handle
        builder.addCase(loginUser.pending, (state) => {
            state.isFetching = true;
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.isLoginSuccess = action.payload.status;
            state.userId = action.payload.response.id;
            state.token = action.payload.response.token;
            state.loginSuccessMessage = action.payload.statusMessage;
            state.isFirstPasswordReset = action.payload.response.hasResetPassword;
            state.profileType = action.payload.response.profileType;
            localStorage.setItem("token", action.payload.response.token);
            localStorage.setItem("userId", action.payload.response.id);
            localStorage.setItem("profileType", action.payload.response.profileType);
        })
        builder.addCase(loginUser.rejected, (state, action) => {
            state.isLoginError = true;
            state.loginFailureMessage = action.payload.statusMessage; 
        })


        // Reset Passowrd for the first time
        builder.addCase(resetFirstPassword.pending, (state) => {
            state.isFetching = true;
        })
        builder.addCase(resetFirstPassword.fulfilled, (state, action) => {
            state.isFirstPasswordReset = true;
            state.firstPasswordResetSuccessMessage = action.payload.statusMessage;
            state.userId = action.payload.response.id;
            state.token = action.payload.response.token;
            state.profileType = action.payload.response.profileType;
            localStorage.setItem("token", action.payload.response.token);
            localStorage.setItem("userId", action.payload.response.id);
            localStorage.setItem("profileType", action.payload.response.profileType);

        })
        builder.addCase(resetFirstPassword.rejected, (state, action) => {
            state.isFirstPasswordReset = false;
            state.firstPasswordResetError = true;
            state.firstPasswordResetFailureMessage = action.payload.statusMessage;
            // state.firstPasswordResetMessage = null
            // state.errorMessage = action.payload.statusMessage;
        })
        //  Logout User
        builder.addCase(logoutUser.fulfilled, (state) => {
            state.auth = null;
            state.isLoggedIn = false
            localStorage.clear()
        })
    }
});


// export const {} = userSlice.actions
export const { clearState } = signUpSlice.actions;
export const authSliceHandler = state => state.auth;
export const signupVerification = state => state.signupVerification;
export default signUpSlice.reducer;
