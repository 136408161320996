import axiosInstance from "../utils/axiosInstance";

export const apiGetData = (url, config = {headers: { "content-type": "application/json", Accept: "application/json" }}) => {
  return axiosInstance.get(url, config);
};

export const apiPostData = (url, body, config = {headers: { "content-type": "application/json", Accept: "application/json" }}) => {
  return axiosInstance.post(url, body, config);
};

export const apiUpdateData = (url, body, config = {}) => {
  return axiosInstance.put(url, body, config);
};

export const apiDeleteData = (url) => {
  return axiosInstance.delete(url);
};
