import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_PROJECT_TIMELINE } from "src/constants/apiUrls";
import { apiGetData } from "src/services/api.service";

export const getTimelineDetails = createAsyncThunk(
  "@timeline/getTimelineDetails",
  async (id, thunkAPI) => {
    const url = `${API_PROJECT_TIMELINE}`;
    try {
      const response = await apiGetData(`${url}/${id}`);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initJobSlice = createSlice({
  name: "timeline",

  initialState: {
    jobs: [],
    show: {},
    projectId: "",
    dateFrom: new Date("2023-05-01"),
    dateTo: new Date("2024-04-01"),
    resources: [],
    loading: "idle", // 'idle' | 'pending'
    error: null,
  },
  reducers: {
    createShow: (state, action) => {
      state.show = action.payload;
    },
    updateShow: (state, action) => {
      state.show = { ...state.show, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimelineDetails.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getTimelineDetails.fulfilled, (state, action) => {
      state.loading = "idle";
      console.log(action.payload);
      state.jobs = action.payload.stages;
      state.projectId = action.payload._id;
      state.show = action.payload.stages.reduce((acc, post) => {
        const { _id } = post;
        return { ...acc, [_id]: false };
      }, {});
      state.dateFrom = action.payload?.startDate;
      state.dateTo = action.payload?.endDate;
      state.resources = action.payload.stages.map(({ _id, name }) => ({
        _id,
        name,
      }));
    });
    builder.addCase(getTimelineDetails.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

export const { updateShow, createShow } = initJobSlice.actions;
export default initJobSlice.reducer;
