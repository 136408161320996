/* eslint-disable no-unused-vars */

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../../../../constants/helper";

const PrivateRoutes = () => {
    const token = getToken();
    const auth = {token: !!token};
    return(
        auth.token ? <Outlet /> : <Navigate to='/login' replace />
    )
};

export default PrivateRoutes;
