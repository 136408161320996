import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API_PROJECT } from "src/constants/apiUrls";
import { apiGetData } from "src/services/api.service";


const initialState = {
    taskChecklist: [],
    error: null
}

export const getTaskChecklists = createAsyncThunk('@taskChecklist/get', async (data, thunkAPI) => {
    const url = `${API_PROJECT}/${data.id}`;
    try {
        const res = await apiGetData(url, data.body);
        const responseData = await res?.data?.response;
        return responseData;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
})

const taskChecklistSlice = createSlice({
    name: "taskChecklist",
    initialState,
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getTaskChecklists.pending, (state) => {
            state.loading = "pending";
        })
        builder.addCase(getTaskChecklists.fulfilled, (state, action) => {
            state.taskChecklist = action.payload
        })
        builder.addCase(getTaskChecklists.rejected, (state, action) => {
            state.loading = "idle";
            state.error = action.payload;
        })
    }
})

export const taskChecklistSelector = (state) => state.taskChecklist.taskChecklist;
export default taskChecklistSlice.reducer;