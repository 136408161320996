
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserId } from "src/constants/helper";
import { getUserById } from "src/redux/slice/userSlice";
import NavSide from "../layout/sidebar/NavSide";
import styles from './layoutTemplate.module.scss';

const LayoutTemplate = ({layout, children}) => {
    const userId = getUserId();
    const dispatch = useDispatch();

    useEffect(() => {
        if(layout === 'private') {
            dispatch(getUserById(userId));
        }
    }, [dispatch, layout, userId]);

    return(
        <div className={`${styles.routesContainer} ${styles[layout]}`}>
            {children}
            {layout === 'private' && <NavSide />}
        </div>
    )
};

export default LayoutTemplate;