/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from "@mdi/react";
import { mdiViewDashboardOutline, mdiMagnify, mdiTune, mdiForumOutline, mdiShoppingOutline, mdiChevronRight, mdiBellOutline, mdiCogOutline, mdiHelpCircleOutline, mdiLogout, mdiCalendar, mdiAccountGroup } from '@mdi/js';
import { NavLink } from 'react-router-dom';
import { userSelector } from '../../../../redux/slice/userSlice';
import {URL_CALENDAR, URL_DASHBOARD, URL_LEADS, URL_PROFILE, URL_PROJECT, URL_VENDOR_CATEGORY} from '../../../../constants/routeUrls';
import logo from '../../../../assets/images/ombhuLogo.svg';
import logoText from '../../../../assets/images/ombhuLogoText.svg';
import ProfilePic from '../../ProfilePic';
import InputWithIcon from '../../TextField/InputWithIcon';
import DropdownLink from './DropdownLink';
import styles from "./navSide.module.scss"
import { authSliceHandler } from '../../../../redux/slice/authSlice';

const NavSide = () => {
  const [isExpand, setIsExpand] = useState(false);
  const [isFreez, setIsFreez] = useState(false);
  const user = useSelector(userSelector);
  const profilePic = user?.profileMeta?.profilePicture;
  const profileMeta = user?.profileMeta;
  const name = profileMeta?.name || '';
  // const role = user?.profileType === 'individual' ? profileMeta?.role : profileMeta?.domain.join(', ');
  const role = profileMeta?.role;
  

  const handleHover = () => {
    setIsExpand(true);
  }
  const handleLeave = () => {
    if (!isFreez) setIsExpand(false);
  }

  // const toggleFreez = (status) => {
  //   setIsFreez(status)
  // }

  const logout = useSelector(authSliceHandler);

  // route links should come from constants folder -- pending
  const topLinks = [
    {id: 1, icon: mdiViewDashboardOutline, link: URL_DASHBOARD, text:'Dashboard'},
    {id: 2, icon: mdiTune, text:'Project Management', children: [
      {link: URL_VENDOR_CATEGORY, text:'Vendor List'},
      {link: URL_PROJECT, text:'Projects'}
    ]},
    {id: 3, icon: mdiCalendar, link:URL_CALENDAR, text:'Calendar'},
    {id: 4, icon: mdiAccountGroup, link:URL_LEADS, text:'Leads'}
  ];

  const bottomLinks = [
    // {id: 11, icon: mdiBellOutline, link:'/notification', text:'Notification', count: 20},
    // {id: 12, icon: mdiCogOutline, link:'/settings', text:'Settings'},
    {id: 13, icon: mdiHelpCircleOutline, link:'/faqs', text:'Help/FAQ’s'},
    {id: 14, icon: mdiLogout, link:'/logout', text:'Logout'}
  ]

  return (
    <div className={`${styles.sidebar_container} ${isExpand && styles.expanded}`} onMouseOver={handleHover} onMouseLeave={handleLeave}>
        <div className={styles.sidebar_header}>
          <div>
            <img className={styles.brand} src={isExpand ? logoText : logo} alt="Brand logo" />
          </div>
        </div>
        <div className={styles.top_content}>
          <NavLink to={URL_PROFILE} className={({ isActive }) => isActive ? `${styles.side_link} ${styles.active} ${styles.user_profile}` : `${styles.side_link} ${styles.user_profile}` }>
            <ProfilePic src={profilePic} size='sm' isProfile />
            <div className={styles.hide_if_not_expanded}>
              <div className={`${styles.profile_content} d-flex justify-content-between align-items-center`}>
                <div className='flex-grow-1'>
                  <p className={`${styles.name} text-white mb-0`}>{name}</p>
                  <p className='mb-0 text-white'>{role}</p>
                </div>
                <Icon path={mdiChevronRight} size={1.6} color='#fff' />
              </div>
            </div>
          </NavLink>
          <div className={styles.search_wrapper}>
            <InputWithIcon  position="start" variant="outlined" fieldset={{ borderColor: '#fff !important'}} placeholder={`${isExpand ? 'Search' : ''}`} icon={mdiMagnify} iconColor="#fff" iconSize={1.6} textColor="#fff" />
          </div>
          <div className={styles.links_container}>
            {
              topLinks.map(link => 
                link.link ?  
                  <NavLink key={link.id} to={link.link} className={({ isActive }) => isActive ? `${styles.side_link} ${styles.active}` : styles.side_link }>
                    <Icon path={link.icon} size={1.4} color='#fff' />
                    <div className={styles.hide_if_not_expanded}>
                      <p className={styles.link_text}>{link.text}</p>
                    </div>
                  </NavLink> 
                :
                <DropdownLink key={link.id} isExpand={isExpand} icon={link.icon} dropdownText={link.text} items={link.children} />
              )
            }
          </div>
        </div>
        <div className={styles.bottom_content}>
          {
            bottomLinks.map(link => 
              <NavLink key={link.id} to={link.link} className={({ isActive }) => isActive ? `${styles.side_link} ${styles.active}` : styles.side_link }>
                <Icon path={link.icon} size={1.4} color='#fff' />
                <div className={styles.hide_if_not_expanded}>
                  <p className={`${styles.link_text} ${link.count ? 'd-flex justify-content-between align-items-center' : ''}`}>{link.text} {link.count && <span>20</span>}</p>
                </div>
                {(link.count && !isExpand) &&
                <div className={styles.dot} />
                }
              </NavLink> 
            )
          }
        </div>
    </div>
  );
}

export default NavSide