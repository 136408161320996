import * as React from "react";
import Box from "@mui/material/Box";

import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";

import Icon from "@mdi/react";
import { ThemeProvider } from "@mui/material";
import { theme } from "../Theme/ThemeProvider";

export default function InputWithIcon({
  position = "start",
  iconColor = "#4D4D4D",
  icon,
  height = "46px",
  width = "100%",
  sx,
  fieldset,
  iconSize=1.2,
  textColor="#1a1a1a",
  iconHandler,
  ...rest
}) {
  const positioning = {
    start: "startAdornment",
    end: "endAdornment",
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ "& > :not(style)": { mt: 1 }, width: '100%' }}>
        <TextField
          id="input-with-icon-textfield"
          {...rest}
          sx={{
            maxWidth: "100%",
            width,

            "& legend": { display: "none" },
            "& fieldset": { top: 0, borderRadius: 0, ...fieldset},
            ...sx
          }}
          InputProps={{
            sx: {
              height,
            },

            style: {
              fontSize: "1em",
              color: textColor,
            },

            [positioning[position]]: (
              <InputAdornment position="start" >
                <Icon path={icon} size={iconSize} color={iconColor} iconhandler={iconHandler}/>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
