import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import userReducer from "../slice/userSlice";
import teamReducer from "../slice/teamSlice";
import authReducer from "../slice/authSlice";
import commonReducer from "../slice/commonSlice";
import portfolioReducer from "../slice/portfolioSlice";
import blogReducer from "../slice/blogSlice";
import passwordReducer from "../slice/passwordSlice";
import vendorReducer from "../slice/vendorSlice";
import toastReducer from "../slice/toasterSlice";
import leadReducer from "../slice/leadSlice";
import loaderReducer from "../slice/loaderSlice";
import meetingSliceReducer from "../slice/meetingSlice";
import experReducer from "../slice/expertSlice";
import templatesReducer from "../slice/templatesSlice";
import taskChecklistReducer from '../slice/taskChecklistSlice';
import jobsSliceReducer from "../slice/jobsSlice";
import calendarSliceReducer from "../slice/calendarSlice";
import costingReducer from "../slice/costingSlice";

const persistConfig = {
  key: "root",
  // version: 1,
  storage,
  whitelist: ['user', 'auth'],
};

const reducer = combineReducers({
  user: userReducer,
  team: teamReducer,
  auth: authReducer,
  password: passwordReducer,
  common: commonReducer,
  portfolio: portfolioReducer,
  blog: blogReducer,
  vendor: vendorReducer,
  toaster: toastReducer,
  lead: leadReducer,
  loader: loaderReducer,
  meeting: meetingSliceReducer,
  expert: experReducer,
  templates: templatesReducer,
  taskChecklist: taskChecklistReducer,
  timeline: jobsSliceReducer,
  calendar: calendarSliceReducer,
  costing: costingReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
