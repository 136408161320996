/* eslint-disable import/named */
import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { URL_PROFILE, URL_HOME, URL_REPORTS, URL_PRODUCTS, URL_EXPERT_DETAILS, URL_LOGIN, URL_SIGNUP, URL_VERIFICATION_PAGE, URL_SEND_VERIFICATION_MAIL, URL_PORTFOLIO, URL_PORTFOLIO_ADD_NEW, URL_PORTFOLIO_DETAIL, URL_PORTFOLIO_EDIT, URL_BLOGS, URL_BLOG_DETAIL, URL_BLOG_ADD, URL_BLOG_EDIT, URL_GENERATENEWPASSWORD, URL_FORGOTPASSWORDVERIFICATIONLINK, URL_OTPVERIFICATION, URL_LOGOUT, URL_FORGOT_PASSWORD, URL_RESET_PASSWORD, URL_VENDOR_CATEGORY, URL_VENDOR_LIST, URL_LEADS, URL_MEETINGS_ADD, URL_MEETINGS_EDIT, URL_PROJECT_ADD, URL_PROJECT, URL_CALENDAR, URL_PROJECT_EDIT, PARAM_ID, URL_PROJECT_MANAGEMENT_DETAIL, URL_DASHBOARD} from "./constants/routeUrls";
import PrivateRoutes from "./components/core/layout/routers/PrivateRoute";
import LayoutTemplate from "./components/core/LayoutTemplate";
import Toaster from "./components/core/Toaster";
import Spinner from "./components/core/Spinner";

import { toasterStatusSelector } from "./redux/slice/toasterSlice";

import styles from "./app.module.scss";
import { loaderStatusSelector } from "./redux/slice/loaderSlice";

const Reports = lazy(() => import("./pages/Reports"));
const Products = lazy(() => import("./pages/Products"));
const Login = lazy(() => import("./pages/login/Login"));
const ResetPassword = lazy(() => import("./pages/forgotPassword/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword/ForgotPassword"));
const ForgotPasswordVerificationLink = lazy(() =>
  import("./pages/login/ForgotPasswordVerificationLink")
);
const OtpVerification = lazy(() => import("./pages/login/OtpVerification"));
const Logout = lazy(() => import("./components/Logout"));
const GenerateNewPassword = lazy(() => import("./pages/generateNewPassword/GenerateNewPassword"));
const SendEmailVerification = lazy(() => import("./pages/signup/SendEmailVerification"));
const VerificationPage = lazy(() => import("./pages/signup/VerificationPage"));
const Home = lazy(() => import("./pages/home/Home"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const ExpertDetails = lazy(() => import("./pages/expertDetails/ExpertDetails"));
const BlogForm = lazy(() => import("./components/features/profile/Blog/AddEditBlog"));
const AddEditProject = lazy(() => import("./components/features/profile/Portfolio/AddEditProject"));
const Portfolio = lazy(() => import("./pages/portfolio/Portfolio"));
const PortfolioDetail = lazy(() => import("./pages/portfolioDetail/PortfolioDetail"));
const Blogs = lazy(() => import("./pages/blogs/Blogs"));
const BlogDetail = lazy(() => import("./pages/blogDetail/BlogDetail"));
const VendorCategory = lazy(() => import("./pages/vendorCategory"));
const VendorList = lazy(() => import("./pages/vendorList"));
const Leads = lazy(() => import("./pages/leads"));
const MeetingAdd = lazy(() => import("./pages/meeting"));
// const AddNewProject = lazy(() => import("./pages/newProject"));
const NewProject = lazy(() => import("./pages/newProject"));
const Projects = lazy(() => import("./pages/projects"));
const Calendar = lazy(() => import("./pages/Calendar"));
const ProjectDetails = lazy(() => import("./pages/projectDetailPage"));
const Dashboard = lazy(() => import('./pages/dashboard'));

const protectedRoutes = [
  { path: URL_PROFILE, component: Profile },
  { path: URL_HOME, component: Home },
  { path: URL_REPORTS, component: Reports },
  { path: URL_PRODUCTS, component: Products },
  { path: URL_PORTFOLIO, component: Portfolio },
  { path: URL_DASHBOARD, component: Dashboard },
  { path: URL_PORTFOLIO_ADD_NEW, component: AddEditProject },
  { path: `${URL_PORTFOLIO_EDIT}${PARAM_ID}`, component: AddEditProject },
  { path: `${URL_PORTFOLIO_DETAIL}${PARAM_ID}`, component: PortfolioDetail },
  { path: URL_BLOG_ADD, component: BlogForm },
  { path: `${URL_BLOG_EDIT}${PARAM_ID}`, component: BlogForm },
  { path: URL_BLOGS, component: Blogs },
  { path: `${URL_BLOG_DETAIL}${PARAM_ID}`, component: BlogDetail },
  { path: URL_VENDOR_CATEGORY, component: VendorCategory },
  { path: URL_VENDOR_LIST, component: VendorList },
  { path: URL_PROJECT_ADD, component: NewProject },
  { path: `${URL_PROJECT_EDIT}${PARAM_ID}`, component: NewProject },
  { path: URL_LEADS, component: Leads },
  { path: URL_MEETINGS_ADD, component: MeetingAdd },
  { path: URL_MEETINGS_EDIT, component: MeetingAdd },
  { path: URL_PROJECT, component: Projects },
  { path: URL_CALENDAR, component: Calendar },
  { path: URL_PROJECT_MANAGEMENT_DETAIL, component: ProjectDetails },
  { path: `${URL_PROJECT_MANAGEMENT_DETAIL}${PARAM_ID}`, component: ProjectDetails },
];

const publicRoutes = [
  { path: URL_LOGIN, component: Login, replace: true },
  { path: URL_EXPERT_DETAILS, component: ExpertDetails },
  { path: URL_SEND_VERIFICATION_MAIL, component: SendEmailVerification },
  { path: URL_VERIFICATION_PAGE, component: VerificationPage },
  { path: URL_GENERATENEWPASSWORD, component: GenerateNewPassword },
  {
    path: URL_FORGOTPASSWORDVERIFICATIONLINK,
    component: ForgotPasswordVerificationLink,
  },
  { path: URL_OTPVERIFICATION, component: OtpVerification },
  { path: URL_LOGOUT, component: Logout },
  { path: URL_FORGOT_PASSWORD, component: ForgotPassword },
  { path: `${URL_RESET_PASSWORD}/:id`, component: ResetPassword },
];

const App = () => {
  const showToast = useSelector(toasterStatusSelector);
  const showLoader = useSelector(loaderStatusSelector);
  return (
    <div className={`${styles.mainContainer}`}>
      <Routes>
        {/* Note: page not found has to implement - pending  */}
        <Route exact path="/" element={<Navigate to="/profile" replace />} />

        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<Spinner />}>
                <LayoutTemplate layout="public">
                  <route.component />
                </LayoutTemplate>
              </Suspense>
            }
            {...route}
          />
        ))}

        <Route element={<PrivateRoutes />}>
          {protectedRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<Spinner />}>
                  <LayoutTemplate layout="private">
                    <route.component />
                  </LayoutTemplate>
                </Suspense>
              }
              {...route}
            />
          ))}
        </Route>
      </Routes>

      {showToast && <Toaster />}
      {showLoader && <Spinner isGlobal />}
    </div>
  );
};

export default App;
