/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL, API_GET_CALENDAR } from "../../constants/apiUrls";
import { apiGetData } from "../../services/api.service";

const initialState = {
  events: [],
  loading: "idle",
  error: null,
};

export const getCalendarEvents = createAsyncThunk(
  "@event/getCalendarEvents",
  async (params, thunkAPI) => {
    try {
      const response = await apiGetData(
        `${BASE_URL}${API_GET_CALENDAR}?startDate=${params.startDate}&endDate=${params.endDate}`,
        params
      );
      return response.data?.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCalendarEvents.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getCalendarEvents.fulfilled, (state, action) => {
      state.loading = "idle";
      state.events = action.payload;
    });
    builder.addCase(getCalendarEvents.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

// selectors

export const selectEvents = (state) => state.calendar.events;
export const selectStatus = (state) => state.calendar.loading;
export const selectError = (state) => state.calendar.error;

// export const {} = teamSlice.actions

export default calendarSlice.reducer;
