/* eslint-disable no-unused-vars */
import axios from "axios";
import { BASE_URL, API_FILE_UPLOAD } from "../constants/apiUrls";


const uploadFile = (file, folder) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}${API_FILE_UPLOAD}/${folder}`;

    return axios.post(url, file, config);
};

export default uploadFile;